<template>
  <div class="container">
    <div class="sportWrapper">
      <div class="sportLeft">
        
        <LeftLeague />
        <LeftGym />
        <LeftCode />
      </div>
      <div class="sportCenter">
        <CenterTop />
        <!-- <CenterMap /> -->
        <Map />
        <!-- <CenterBottom /> -->
      </div>
      <div class="sportRight">
        <!-- <RightVideo /> -->
        <RightGuide />
        <!-- <RightActivity /> -->
      </div>
    </div>
  </div>
</template>
  
  <script>
import echarts from "echarts";
import drawMixin from "@/utils/drawMixin";
import { formatTime } from "@/utils/index.js";
import LeftCode from "./components/leftCode";
import LeftLeague from "./components/leftLeague";
import LeftGym from "./components/leftGym";
import RightVideo from "./components/rightVideo";
import RightGuide from "./components/rightGuide";
import RightActivity from "./components/rightActivity";
import CenterTop from "./components/centerTop";
// import CenterMap from "./components/centerMap";
import CenterBottom from "./components/centerBottom";
import Map from "./components/Map";

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
    };
  },
  components: {
    LeftCode,
    LeftLeague,
    LeftGym,
    RightVideo,
    RightGuide,
    RightActivity,
    CenterTop,
    // CenterMap,
    CenterBottom,
    Map,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../../assets/scss/index.scss";
.container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 16px;
  .sportWrapper {
    width: 100%;
    display: flex;
    // padding: 0 49px;
    .sportLeft {
      width: 450px;
      padding-bottom: 33px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // border: 1px solid #fafafa;
    }
    .sportCenter {
      flex: 1;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      padding:0 51px;
      
      // background: gray;
    }
    .sportRight {
      width: 444px;
      // background: red;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      padding-bottom: 33px;
    }
  }
}
</style>
  