<template>
  <div id="leftGym">
    <div class="leftGymContainer">
      <ContentTop title="商协会统计情况" :type="1" />
      <!-- <div class="pos-relative">
        <AccumulateTip
          iconName="person_icon"
          :paddingTop="24"
          :width="87"
          :height="51"
          label="健身房使用总次数"
          :count="94400"
          unit="人"
        />
      </div> -->
      <div class="centerBottomContainer">
        <div
          class="bottomItem"
          v-for="(item, index) in bottomData"
          :key="index"
        >
          <div class="topWrapper">
            <div class="imgBox">
              <div class="imgInner">
                <img :src="item.icon" alt="" />
              </div>
            </div>
            <div class="countBox">
              <div class="topTitle">{{ item.topTitle }}</div>
              <div class="top" style="margin-top: 6px">
                <span class="topCount">{{ item.topCount }}</span>
                <span class="topLabel">{{ item.topLabel }}</span>
              </div>
            </div>
          </div>
          <div class="bottomWrapper">
            <div class="rankBox">
              <div class="rankItem">
                <div>
                  <span>{{ item.rankLeftCount }}</span
                  ><span class="itemLabel">{{ item.rankLeftLabel }}</span>
                </div>
                <div class="rankTitle">{{ item.rankLeftTitle }}</div>
              </div>
              <div class="rankItem">
                <div>
                  <span>{{ item.rankLeftCount }}</span
                  ><span class="itemLabel">{{ item.rankLeftLabel }}</span>
                </div>
                <div class="rankTitle">{{ item.rankCenterTitle }}</div>
              </div>
              <div class="rankItem">
                <div>
                  <span>{{ item.rankRightCount }}%</span
                  ><span
                    :style="`margin-left: 5px;font-size: 14px; color: ${
                      item.rankRightStatus ? '#e93329' : '#08fefe'
                    }`"
                    :class="`iconfont ${
                      item.rankRightStatus ? 'icon-shang' : 'icon-xiangxia3'
                    } `"
                  ></span>
                </div>
                <div class="rankTitle">{{ item.rankRightTitle }}</div>
              </div>
            </div>
            <!-- <div class="rankBox" style="margin-top: 8px">
              <div class="rankItem">
                <div>
                  <span>{{ item.rateLeftCount }}%</span>
                  <span
                    class="iconfont icon-shang"
                    style="margin-left: 3px; font-size: 14px; color: #08fefe"
                  ></span>
                </div>
                <div class="rankTitle">上月</div>
              </div>
              <div class="rankItem">
                <div>
                  <span>{{ item.rateRightCount }}%</span>
                  <span
                    class="iconfont icon-xiangxia3"
                    style="margin-left: 3px; font-size: 14px; color: #f1ae29"
                  ></span>
                </div>
                <div class="rankTitle">上月</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import order from "../../../assets/sportFacilities/order_icon.png";
import serve from "../../../assets/sportFacilities/serve_icon.png";
import Echart from "@/common/echart";
import ContentTop from "@/components/contentTop";
export default {
  components: {
    Echart,
    ContentTop,
  },
  data() {
    return {
      colorRight: "red",
      bottomData: [
        {
          icon: order,
          topCount: 415390,
          topLabel: "单",
          topTitle: "新增订单数",
          rankLeftCount: 1200,
          rankLeftLabel: "单",
          rankLeftTitle: "今日",
          rankCenterCount: 1200,
          rankCenterLabel: "单",
          rankCenterTitle: "本月",
          rankRightCount: 10,
          rankRightStatus: true,
          rankRightTitle: "较上月",
        },
        {
          icon: serve,
          topCount: 415390,
          topLabel: "单",
          topTitle: "分享次数",
          rankLeftCount: 1200,
          rankLeftLabel: "单",
          rankLeftTitle: "今日",
          rankCenterCount: 1200,
          rankCenterLabel: "单",
          rankCenterTitle: "本月",
          rankRightCount: 10,
          rankRightStatus: false,
          rankRightTitle: "较上月",
        },
        // {
        //   icon: serve,
        //   topCount: 893067,
        //   topLabel: "人",
        //   topTitle: "赛务人次",
        //   rankLeftCount: 87766,
        //   rankLeftLabel: "次",
        //   rankLeftTitle: "本月赛事次数",
        //   rankRightCount: 12456,
        //   rankRightLabel: "次",
        //   rankRightTitle: "本月赛事服务人次",
        //   rateLeftCount: 10,
        //   rateLeftTitle: "上月",
        //   rateRightCount: 10,
        //   rateRightTitle: "上月",
        // },
        // {
        //   icon: guide,
        //   topCount: 490867,
        //   topLabel: "场",
        //   topTitle: "指导员团课",
        //   rankLeftCount: 49832,
        //   rankLeftLabel: "次",
        //   rankLeftTitle: "发布次数",
        //   rankRightCount: 46589,
        //   rankRightLabel: "人",
        //   rankRightTitle: "参与团课人数",
        //   rateLeftCount: 10,
        //   rateLeftTitle: "上月",
        //   rateRightCount: 10,
        //   rateRightTitle: "上月",
        // },
      ],
    };
  },
  methods: {
    handleChange(index, value) {
      console.log(index, value);
    },
  },
};
</script>
      
      <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#leftGym {
  margin-top: 24px;
  .leftGymContainer {
    width: 100%;
    .centerBottomContainer {
      width: 100%;
      text-align: center;
      // margin-bottom: 33px;
      // margin-bottom: 21px;
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bottomItem {
        padding: 16px 0 16px;
        background-image: url("~@/assets/sportFacilities/bottom_bg.png");
        background-size: 100% 100%;
        width: 48%;
        .topWrapper {
          display: flex;
          align-items: center;
          // padding-top: 11px;
          padding-left: 16px;
          .imgBox {
            width: 39px;
            height: 39px;
            // border: 1px solid rgba(255, 255, 255, 0.24);
            // border-radius: 50%;
            background: #697184;
            text-align: center;
            .imgInner {
              width: 24px;
              height: 24px;
              margin: 0 auto;
              margin-top: 7px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .countBox {
            margin-left: 14px;
            text-align: left;
            .top {
              .topCount {
                color: #08fefe;
                font-size: 20px;
                font-family: Source Han Sans CN, Source Han Sans CN-Bold;
                font-weight: 700;
              }
              .topLabel {
                margin-left: 3px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.7);
              }
            }
            .topTitle {
              margin-top: 4px;
              font-weight: 700;
              text-align: left;
            }
          }
        }
        .bottomWrapper {
          padding: 0 6px;
          margin-top: 12px;
          font-weight: 700;
          font-size: 14px;
          .rankBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .rankItem {
              display: flex;
              justify-content: center;
              flex-direction: column;
              .itemLabel {
                margin-left: 3px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);
              }
              .rankTitle {
                margin-top: 2px;
                color: rgba(255, 255, 255, 0.5);
              }
            }
          }
        }
      }
    }
  }
}
</style>
      