<template>
  <div class="centerBottomContainer">
    <div class="bottomItem" v-for="(item, index) in bottomData" :key="index">
      <div class="topWrapper">
        <div class="imgBox">
          <div class="imgInner">
            <img :src="item.icon" alt="" />
          </div>
        </div>
        <div class="countBox">
          <div class="top">
            <span class="topCount">{{ item.topCount }}</span>
            <span class="topLabel">{{ item.topLabel }}</span>
          </div>
          <div class="topTitle">{{ item.topTitle }}</div>
        </div>
      </div>
      <div class="bottomWrapper">
        <div class="rankBox">
          <div class="rankItem">
            <div>
              <span>{{ item.rankLeftCount }}</span
              ><span class="itemLabel">{{ item.rankLeftLabel }}</span>
            </div>
            <div class="rankTitle">{{ item.rankLeftTitle }}</div>
          </div>
          <div class="rankItem">
            <div>
              <span>{{ item.rankRightCount }}</span
              ><span>{{ item.rankRightLabel }}</span>
            </div>
            <div class="rankTitle">{{ item.rankRightTitle }}</div>
          </div>
        </div>
        <div class="rankBox" style="margin-top: 8px;">
          <div class="rankItem">
            <div>
              <span>{{ item.rateLeftCount }}%</span>
              <span
                class="iconfont icon-shang"
                style="margin-left: 3px;font-size: 14px; color: #08fefe"
              ></span>
            </div>
            <div class="rankTitle">上月</div>
          </div>
          <div class="rankItem">
            <div>
              <span>{{ item.rateRightCount }}%</span>
              <span
                class="iconfont icon-xiangxia3"
                style="margin-left: 3px;font-size: 14px; color: #f1ae29"
              ></span>
            </div>
            <div class="rankTitle">上月</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
          
          <script>
import order from "../../../assets/sportFacilities/order_icon.png";
import serve from "../../../assets/sportFacilities/serve_icon.png";
import guide from "../../../assets/sportFacilities/guide.png";
export default {
  components: {},
  data() {
    return {
      bottomData: [
        {
          icon: order,
          topCount: 415390,
          topLabel: "份",
          topTitle: "订单情况",
          rankLeftCount: 87766,
          rankLeftLabel: "份",
          rankLeftTitle: "体育馆",
          rankRightCount: 46589,
          rankRightLabel: "份",
          rankRightTitle: "运动场所",
          rateLeftCount: 10,
          rateRightCount: 10,
        },
        {
          icon: serve,
          topCount: 893067,
          topLabel: "人",
          topTitle: "赛事总服务人次",
          rankLeftCount: 87766,
          rankLeftLabel: "次",
          rankLeftTitle: "本月赛事次数",
          rankRightCount: 12456,
          rankRightLabel: "次",
          rankRightTitle: "本月赛事服务人次",
          rateLeftCount: 10,
          rateLeftTitle: "上月",
          rateRightCount: 10,
          rateRightTitle: "上月",
        },
        {
          icon: guide,
          topCount: 490867,
          topLabel: "场",
          topTitle: "指导员团课",
          rankLeftCount: 49832,
          rankLeftLabel: "次",
          rankLeftTitle: "发布次数",
          rankRightCount: 46589,
          rankRightLabel: "人",
          rankRightTitle: "参与团课人数",
          rateLeftCount: 10,
          rateLeftTitle: "上月",
          rateRightCount: 10,
          rateRightTitle: "上月",
        },
      ],
    };
  },
};
</script>
          
          <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
.centerBottomContainer {
  width: 100%;
  text-align: center;
  // margin-bottom: 33px;
  // margin-bottom: 21px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bottomItem {
    padding: 11px 0 9px;
    background-image: url("~@/assets/sportFacilities/bottom_bg.png");
    background-size: 100% 100%;
    width: 260px;
    .topWrapper {
      display: flex;
      padding-top: 11px;
      padding-left: 38px;
      .imgBox {
        width: 39px;
        height: 39px;
        border: 1px solid rgba(255, 255, 255, 0.24);
        border-radius: 50%;
        text-align: center;
        .imgInner {
          width: 24px;
          height: 24px;
          margin: 0 auto;
          margin-top: 7px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .countBox {
        margin-left: 14px;
        text-align: left;
        .top {
          .topCount {
            color: #d4d739;
            font-size: 20px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
          }
          .topLabel {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.7);
          }
        }
        .topTitle {
          margin-top: 4px;
          font-weight: 700;
          text-align: left;
        }
      }
    }
    .bottomWrapper {
      margin-top: 5px;
      font-weight: 700;
      font-size: 14px;
      .rankBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .rankItem {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .itemLabel {
            margin-left: 3px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
          }
          .rankTitle {
            margin-top: 2px;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}
</style>
          