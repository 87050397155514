<template>
  <div id="leftLeague">
    <div class="leftLeagueContainer">
      <ContentTop title="商协会管理情况" :type="1" />
      <div class="pos-relative" style="margin-top: 24px;">
        <AccumulateTip
          :width="66"
          label="商协会总人数"
          :count="4478900"
          unit="人"
        />
        <div class="d-flex jc-between" style="margin-top: 10px">
          <div class="leagueCenter">
            <Echart
              :options="options"
              id="bottomLeftChart2"
              height="164px"
            ></Echart>
          </div>
          <div class="leagueItem">
            <div class="itemBox top">
              <div class="leftItemBox">商会</div>
              <div class="rightItemBox">
                <div>
                  <span>数量</span>
                  <span style="margin-left: 8px">3,800个</span>
                </div>
                <div>
                  <span>人数</span>
                  <span style="margin-left: 8px">6,200人</span>
                </div>
              </div>
            </div>
            <div class="itemBox bottom">
              <div class="leftItemBox">协会</div>
              <div class="rightItemBox">
                <div>
                  <span>数量</span>
                  <span style="margin-left: 8px">3,800个</span>
                </div>
                <div>
                  <span>人数</span>
                  <span style="margin-left: 8px">6,200人</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import echarts from "echarts";
import Echart from "@/common/echart";
import ContentTop from "@/components/contentTop";
import AccumulateTip from "@/components/accumulateTip";
export default {
  components: {
    Echart,
    ContentTop,
    AccumulateTip,
  },
  data() {
    return {
      colorRight: "#6DC5F2",
      options: {
        color: ["#6dc5f2", "#ed73e8"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        grid: {
          // 让图表占满容器
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        calculable: true,
        series: [
          {
            name: "访问来源",
            type: "pie",
            selectedMode: "single",
            raduis: [0, "100%"],
            label: {
              normal: {
                position: "inner",
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              { value: 679, name: "协会" },
              { value: 935, name: "商会" },
            ],
          },
        ],
      },
    };
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#leftLeague {
  .leftLeagueContainer {
    width: 100%;
    .leagueCenter {
      flex: 1;
      //   background: red;
    }
    .leagueItem {
      flex: 1;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      .itemBox {
        margin-top: 10px;
        height: 70px;
        display: flex;
        &.top {
          background: url("~@/assets/sportFacilities/city_icon.png") no-repeat;
          background-size: 100% 100%;
        }
        &.bottom {
          background: url("~@/assets/sportFacilities/xian_icon.png") no-repeat;
          background-size: 100% 100%;
        }
        .leftItemBox {
          // width: 24px;
          padding-top: 26px;
          margin-left: 26px;
        }

        .rightItemBox {
          padding-top: 18px;
          margin-left: 55px;
        }
      }
    }
  }
}
</style>
    