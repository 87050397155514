<template>
  <div id="centerMap">
    <div class="centerMapContainer">
      <!-- <div class="flyBox">
        <dv-flyline-chart-enhanced
          :config="config"
          style="width: 100%; height: 100%; z-index: 10000"
          :dev="true"
        />
      </div> -->
      <div id="echartMap" ref="echartMap" class="echartMap"></div>
      <div class="mapTip">名片使用热度指数</div>
      <div class="areaBox">
        <CenterArea />
      </div>
    </div>
  </div>
</template>
      
      <script>
import echarts from "echarts";
import "echarts-gl";

import china from "../../../assets/source/china.json";
// import jn from "../../../assets/source/jn.json";
import CenterArea from "./CenterArea";

export default {
  components: {
    CenterArea,
  },
  data() {
    return {
      mapdata: [],
      config: {
        line: {
          width: 2,
        },
        points: [
          {
            name: "瓯海区",
            coordinate: [0.46, 0.38],
            halo: {
              show: true,
            },
          },
          {
            name: "龙湾区",
            coordinate: [0.49, 0.35],
          },
          {
            name: "鹿城区",
            coordinate: [0.44, 0.32],
          },
          {
            name: "永嘉县",
            coordinate: [0.5, 0.26],
          },
          {
            name: "乐清市",
            coordinate: [0.6, 0.27],
          },
          {
            name: "瑞安市",
            coordinate: [0.49, 0.44],
          },
          {
            name: "文成县",
            coordinate: [0.28, 0.45],
          },
          {
            name: "平阳县",
            coordinate: [0.36, 0.5],
          },
          {
            name: "龙港市",
            coordinate: [0.48, 0.54],
          },
          {
            name: "洞头区",
            coordinate: [0.63, 0.41],
          },
          {
            name: "泰顺县",
            coordinate: [0.23, 0.57],
            halo: {
              show: true,
              color: "#8378ea",
            },
          },
          {
            name: "苍南县",
            coordinate: [0.42, 0.62],
            halo: {
              show: true,
              color: "#37a2da",
            },
          },
        ],
        lines: [
          {
            source: "苍南县",
            target: "瓯海区",
          },
          {
            source: "泰顺县",
            target: "瓯海区",
          },
          {
            source: "龙港市",
            target: "瓯海区",
          },
          {
            source: "平阳县",
            target: "瓯海区",
          },
          {
            source: "乐清市",
            target: "瓯海区",
          },
          {
            source: "苍南县",
            target: "瑞安市",
          },
          {
            source: "泰顺县",
            target: "鹿城区",
          },
          {
            source: "苍南县",
            target: "文成县",
          },
          {
            source: "泰顺县",
            target: "平阳县",
          },
          {
            source: "洞头区",
            target: "永嘉县",
          },
          {
            source: "乐清市",
            target: "鹿城区",
          },
          {
            source: "泰顺县",
            target: "鹿城区",
          },
        ],
        areaList: [
          { value: 30, name: "瓯海区" },
          { value: 80, name: "龙湾区" },
          { value: 50, name: "鹿城区" },
          { value: 44, name: "永嘉县" },
          { value: 60, name: "乐清市" },
          { value: 48, name: "瑞安市" },
          { value: 75, name: "文成县" },
          { value: 80, name: "平阳县" },
          { value: 84, name: "龙港市" },
          { value: 30, name: "洞头区" },
          { value: 30, name: "泰顺县" },
          { value: 30, name: "苍南县" },
        ],
      },
      pointsData: [
        [120.637145, 28.006444],
        [120.690968, 28.153886],
        [120.406256, 27.507743],
        [120.967147, 28.116083],
        [120.09245, 27.789133],
        [119.71624, 27.557309],
      ],
    };
  },
  mounted() {
    this.getEchartMap();
  },
  methods: {
    getEchartMap() {
      console.log(this.pointsData);
      debugger;
      echarts.registerMap("organ_diagram", china);
      var chart = echarts.init(document.getElementById("echartMap"));
      chart.setOption({
        backgroundColor: "transparent",
        // title: {
        //   text: "Visit Route",
        //   left: "center",
        //   bottom: 10,
        // },
        // tooltip: {
        //   trigger: "item",
        //   backgroundColor: "rgba(166, 200, 76, 0.82)",
        //   borderColor: "#FFFFCC",
        //   showDelay: 0,
        //   hideDelay: 0,
        //   enterable: true,
        //   transitionDuration: 0,
        //   extraCssText: "z-index:100",
        //   formatter: function (params, ticket, callback) {
        //     console.log(params);
        //     var res = "";
        //     var name = params.name;
        //     var value = params.value[params.seriesIndex + 1] || params.value;
        //     res =
        //       "<span style='color:#fff;'>" +
        //       name +
        //       "</span><br/>数据：" +
        //       value;
        //     return res;
        //   },
        // },
        visualMap: {
          type: "piecewise",
          min: 0,
          max: 100,

          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
          pieces: [
            { gt: 80, lte: 100, color: "#fb6a00", label: "4 -" },
            { gt: 60, lte: 80, color: "#f3b203", label: "3 -" },
            { gt: 40, lte: 60, color: "#ffd83a", label: "2 -" },
            { gt: 20, lte: 40, color: "#2f72cc", label: "1 -" },
            { gt: 0, lte: 20, color: "#09428e", label: "0 -" },
          ],
          // itemWidth: 10, //图形的宽度，即长条的宽度。
          // itemHeight: 90, //图形的高度，即长条的高度。
          align: "auto", //指定组件中手柄和文字的摆放位置.可选值为：‘auto’ 自动决定。‘left’ 手柄和label在右。‘right’ 手柄和label在左。‘top’ 手柄和label在下。‘bottom’ 手柄和label在上。
          left: "92%", //组件离容器左侧的距离,‘left’, ‘center’, ‘right’,‘20%’
          top: "23% ", //组件离容器上侧的距离,‘top’, ‘middle’, ‘bottom’,‘20%’
          right: "auto", //组件离容器右侧的距离,‘20%’
          bottom: "auto", //组件离容器下侧的距离,‘20%’
          orient: "vertical", //图例排列方向
          show: true,
        },
        geo: [
          {
            map: "organ_diagram",
            aspectScale: 0.75,
            zoom: 0.95,
            layoutCenter: ["50%", "50%"],
            layoutSize: "100%",
            roam: false,
            show: true,
            itemStyle: {
              normal: {
                areaColor: "#f5f5f5",
                shadowColor: "rgba(56, 171, 241, 0.8)",
                shadowOffsetX: 0,
                shadowOffsetY: 15,
              },
              emphasis: {
                areaColor: "#2AB8FF",
                borderWidth: 0,
                color: "green",
                label: {
                  show: false,
                },
              },
            },
          },
          // 重影
          {
            type: "map",
            map: "organ_diagram",
            zlevel: -1,
            aspectScale: 0.75,
            zoom: 0.95,
            layoutCenter: ["50%", "51%"],
            layoutSize: "100%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                borderWidth: 1,
                // borderColor:"rgba(17, 149, 216,0.6)",
                borderColor: "rgba(58,149,253,0.8)",
                shadowColor: "rgba(172, 122, 255,0.5)",
                shadowOffsetY: 5,
                shadowBlur: 15,
                areaColor: "rgba(5,21,35,0.1)",
              },
            },
          },
          {
            type: "map",
            map: "organ_diagram",
            zlevel: -2,
            aspectScale: 0.75,
            zoom: 0.95,
            layoutCenter: ["50%", "52%"],
            layoutSize: "100%",
            roam: false,
            silent: true,
            itemStyle: {
              normal: {
                borderWidth: 1,
                // borderColor: "rgba(57, 132, 188,0.4)",
                borderColor: "rgba(58,149,253,0.6)",
                shadowColor: "rgba(65, 214, 255,1)",
                shadowOffsetY: 5,
                shadowBlur: 10,
                areaColor: "transpercent",
              },
            },
          },
        ],
        // grid: {
        //   left: '50%',
        //   top: '20%',
        //   bottom: '20%'
        // },
        series: [
          {
            name: "French Beef Cuts",
            type: "map",
            map: "organ_diagram",
            roam: false,
            zoom: 0.95,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: "100%",
            aspectScale: 0.75,
            // right: "800",
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontSize: 14,
                fontWeight: 600,
              },
              emphasis: {
                show: true,
                color: "#fff",
              },
            },
            itemStyle: {
              areaColor: "#09428e",
            },
            selectedMode: true,
            data: [
              {
                name: "香港",
                itemStyle: {
                  normal: {
                    opacity: 0
                  }
                },
              },
              {
                name: "澳门",
                itemStyle: {
                  normal: {
                    opacity: 0
                  }
                },
              },
              { name: "重庆", value: 70 },
              { name: "江苏", value: 90 },
              { name: "浙江", value: 85 },
              { name: "广东", value: 95 },
              { name: "海南", value: 60 },
              { name: "福建", value: 48 },
              { name: "湖南", value: 75 },
              { name: "安徽", value: 80 },
              { name: "北京", value: 84 },
              { name: "云南", value: 39 },
              { name: "广西", value: 36},
              { name: "青海", value: 35 },
              { name: "江西", value: 60 },
              { name: "湖北", value: 70 },
              { name: "河南", value: 50 },
              { name: "山东", value: 70 },
              { name: "四川", value: 60 },
              { name: "台湾", value: 80 },
              { name: "河北", value: 36 },
            ],
          },
        ],
      });
      var flag = true; // 为了做判断：当鼠标移动上去的时候，自动高亮就被取消
      var _this = this;
      var index = 0;
      // 鼠标移动上去的时候的高亮动画
      chart.on("mouseover", function (param) {
        flag = false;
        clearInterval(_this.startCharts);
        //取消之前高亮图形
        chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index,
        });

        index = param.dataIndex;
        //**解决文章所述问题**// //记录上一次选中值重新进入时取消高亮

        //高亮当前图形
        chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        });
        //显示tooltip
        chart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        });
      });

      //图标随窗口大小缩放
      window.addEventListener("resize", function () {
        chart.resize();
      });
      chart.on("click", function (event) {
        console.log(event);
        chart.dispatchAction({
          type: "highlight",
          geoIndex: 100,
          name: event.name,
        });
      });

      //自动高亮显示
      var chartHover = function () {
        var dataLen = 2;

        // 取消之前高亮的图形
        chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index,
        });
        index = (index + 1) % dataLen;

        // 高亮当前图形
        chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index,
        });
        // 显示 tooltip
        chart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        });
      };

      _this.startCharts = setInterval(chartHover, 5000);
      // 4、鼠标移出之后，恢复自动高亮
      chart.on("mouseout", function (param) {
        if (!flag) {
          _this.startCharts = setInterval(chartHover, 5000);
          flag = true;
        }
      });
    },
  },
};
</script>
      
      <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#centerMap {
  padding-top: 20px;
  .centerMapContainer {
    // height: 262px;
    width: 100%;
    height: 800px;
    position: relative;
    .flyBox {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1000000;
      //   background: #f5f5f5;
    }
    #echartMap {
      width: 100%;
      height: 100%;
      height: 800px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-56%, -50%);
      //   margin: 0 auto;
    }

    .mapTip {
      position: absolute;
      top: 20.8%;
      right: 2%;
      width: 20px;
      color: #fff;
      font-size: 16px;
    }

    .areaBox {
      position: absolute;
      bottom: 30px;
      right: 0;
    }

    // padding: 20px 30px;
  }
}
</style>
      