<template>
  <el-radio-group
    v-model="radioData"
    size="mini"
    @change="$value => $emit('radioChange', radioProps.name, $value)"
    class="radio-wrapper"
    :style="`width: ${radioProps.width}px`">
    <el-radio-button 
      v-for="(radio, index) in radioProps.options"
      :key="radio.id || index"
      :label="radio.label"
      class="radio-item" />
  </el-radio-group>
</template>

<script>
export default {
  props: {
    radioProps: { type: Object, require: true }
  },
  data() {
    return {
      radioData: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-wrapper {
  padding: 2px 0;
  .radio-item {
    width: auto;
    border: 0.5px solid #5694ff;
    background: transparent;
    margin-right: 10px;
    height: 25px;
    line-height: 22px;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
    &.is-active {
      background: #5694ff;
      ::v-deep span {
        color: #ffffff;
      }
    }
    ::v-deep span {
      font-size: 12px;
      color: #5694ff;
      padding: 1px 5px;
      background: transparent !important;
      border-left: 0 !important;
      border-radius: 0 !important;
      border-color: transparent;
      box-shadow: none;
    }
  }
}
</style>