<template>
  <div class="centerTopContainer">
    <div class="topTitle"><span class="innerTitle">名片使用累计总人次</span></div>
    <div class="topPlate">
      <light-plate :lightNum="[9, 6, 3, 7, 8, 9, 0, 6]" />
    </div>
    <div class="topDesc">
      <span>今日：24569人</span>
      <span>本月：12578人</span>
      <span
        >较上月人数：12789人
        <span
          class="iconfont icon-shang"
          style="font-size: 18px; color: #ef3327"
        ></span
      ></span>
    </div>
  </div>
</template>

<script>
import LightPlate from '@/components/lightPlate'
export default {
  components: {
    LightPlate,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/index.scss';
.centerTopContainer {
  width: 100%;
  text-align: center;
  .topTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0px 2px 7px 0px #0d65cd;
    .innerTitle {
      &::before {
            content: '';
            margin-right: 5px;
            display: inline-block;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 10px solid #709efb;
          }
          &::after {
            content: '';
            margin-left: 5px;
            display: inline-block;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 10px solid #709efb;
          }
    }
  }
  .topPlate {
    width: 73%;
    height: 69px;
    margin: 0 auto;
    margin-top: 13px;
  }
  .topDesc {
    width: 62.6%;
    margin: 0 auto;
    margin-top: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    background-image: url('~@/assets/sportFacilities/top_bg.png');
    background-size: cover;
    padding: 6px 0;
  }
}
</style>
