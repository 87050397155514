<template>
  <div class="rankList" :style="`wdith:${listWidth}px;height:${listHeight}px`">
    <div class="rankTitle">
      <div
        class="titleItem"
        v-for="(item, index) in tableTitle"
        :key="index"
        :style="`width:${item.width}px`"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="rankContent">
      <template v-if="viewType == 'default'">
        <div
          class="contetnItem"
          :class="index < 3 ? 'topThree' : 'cusBorder'"
          v-for="(item, index) in list"
          :key="index"
        >
          <div
            class="titleItem overHidden"
            v-for="(i, j) in tableTitle"
            :key="j"
            :style="`width:${i.width}px;max-width:${maxWidth}px;`"
          >
            <template v-if="j != 0">
              <!-- 如果是图片 -->
              <template v-if="j == imgIndex && hasImg">
                <!-- <img :src="list[index][i.val]"> -->
                <img
                  src="https://img1.baidu.com/it/u=4048071112,566005454&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
                  alt=""
                />
              </template>
              <template v-else>
                {{ list[index][i.val] }}
              </template>
            </template>
            <span v-else :class="index < 3 ? `top${index}` : 'topOther'">{{
              returnCount(index)
            }}</span>
          </div>
        </div>
      </template>
      <!-- 如果是普通排行榜 -->
      <template v-else>
        <div class="contetnItem" v-for="(item, index) in list" :key="index">
          <div
            class="titleItem"
            v-for="(i, j) in tableTitle"
            :key="j"
            :style="`width:${i.width}px`"
          >
            <!-- 需要排名显示 -->
            <template v-if="i.title == '排名'">
              <template v-if="j != 0">
                {{ list[index][i.val] }}
              </template>
              <span v-else>{{ returnCount(index) }}</span>
            </template>
            <template>
              {{ list[index][i.val] }}
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    tableTitle: {
      type: Array,
      default: () => [
        {
          title: '排名',
          width: '80',
        },
        {
          title: '姓名',
          val: 'first',
          width: '120',
        },
        {
          title: '课程次数',
          val: 'second',
          width: '100',
        },
        {
          title: '服务人次',
          val: 'three',
          width: '100',
        },
      ],
    },
    list: {
      type: Array,
      default: () => [
        {
          first: '张三',
          second: '95',
          three: '89',
        },
        {
          first: '李四',
          second: '95',
          three: '89',
        },
        {
          first: '王五',
          second: '95',
          three: '89',
        },
        {
          first: '张三',
          second: '95',
          three: '89',
        },
        {
          first: '李四',
          second: '95',
          three: '89',
        },
        {
          first: '王五',
          second: '95',
          three: '89',
        },
        {
          first: '张三',
          second: '95',
          three: '89',
        },
        {
          first: '李四',
          second: '95',
          three: '89',
        },
        {
          first: '王五',
          second: '95',
          three: '89',
        },
        {
          first: '张三',
          second: '95',
          three: '89',
        },
        {
          first: '李四',
          second: '95',
          three: '89',
        },
        {
          first: '张三',
          second: '95',
          three: '89',
        },
        {
          first: '王五',
          second: '95',
          three: '89',
        },
        {
          first: '王五',
          second: '95',
          three: '89',
        },
        {
          first: '张三',
          second: '95',
          three: '89',
        },
        {
          first: '李四',
          second: '95',
          three: '89',
        },
        {
          first: '王五',
          second: '95',
          three: '89',
        },
        {
          first: '张三',
          second: '95',
          three: '89',
        },
      ],
    },
    listWidth: {
      type: Number,
      default: 400,
    },
    listHeight: {
      type: Number,
      default: 500,
    },
    viewType: {
      type: String,
      default: 'default',
    },
    hasImg: {
      type: Boolean,
      default: false,
    },
    imgIndex: {
      type: Number,
      default: 1,
    },
    maxWidth: {
      type: String,
      default: 'auto',
    },
  },
  methods: {
    returnCount(count) {
      ++count
      return count < 10 ? `0${count}` : count
    },
  },
}
</script>

<style lang="scss" scoped>
.rankList {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
  .rankTitle {
    width: 100%;
    height: 50px;
    padding-right: 10px;

    display: flex;
    align-items: center;
    background: rgba(19, 35, 83, 0.87);
    border: 1px solid;
    border-image: linear-gradient(
        105deg,
        rgba(0, 99, 255, 0) 0%,
        #9ab6e3 100%,
        rgba(4, 75, 189, 0) 100%
      )
      1 1;
  }

  .rankContent {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    padding-right: 10px;
    margin-top: 10px;
    .contetnItem {
      display: flex;
      align-items: center;
      .titleItem {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        span {
          width: 50px;
          line-height: 30px;
        }
      }
    }
    .contetnItem + .contetnItem {
      margin-top: 10px;
    }
  }

  .titleItem {
    text-align: center;
    line-height: 40px;
    img {
      width: 20px;
      height: 20px;
      line-height: 40px;
    }
  }

  .rankContent::-webkit-scrollbar {
    width: 3px;
  }

  .rankContent::-webkit-scrollbar-thumb {
    background-color: #85ace9;
  }

  .rankContent::-webkit-scrollbar-track {
    background-color: #020927;
  }

  .cusBorder {
    background: url('~@/assets/rankList/cusBorder.png');
    background-size: cover;
    border-radius: 4px;
  }
  .topThree {
    background: url('~@/assets/rankList/topThree.png');
    background-size: cover;
  }
  .top0 {
    background: url('~@/assets/rankList/top1.png');
    background-size: cover;
  }
  .top1 {
    background: url('~@/assets/rankList/top2.png');
    background-size: cover;
  }
  .top2 {
    background: url('~@/assets/rankList/top2.png');
    background-size: cover;
  }
  .topOther {
    background: url('~@/assets/rankList/top3.png');
    background-size: cover;
  }

  .overHidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
