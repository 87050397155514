<template>
  <div id="rightGuide">
    <div class="rightGuideContainer">
      <ContentTop title="名片管理使用排行" :type="1" />
      <div>
        <AccumulateTip
          :paddingTop="13"
          :width="66"
          label="使用名片总人数"
          :count="736789"
          unit="人"
        />
        <div style="margin-top: 18px">
          <rank-list :listWidth="400" :listHeight="820" :tableTitle="areaTableTitleList" :list="cardList" :imgIndex="2" :maxWidth="100"  :hasImg="true"/>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import echarts from "echarts";
import Echart from "@/common/echart";
import ContentTop from "@/components/contentTop";
import AccumulateTip from "@/components/accumulateTip";
import rankList from "@/components/rankList";
export default {
  components: {
    Echart,
    ContentTop,
    AccumulateTip,
    rankList,
  },
  data() {
    return {
      areaTableTitleList: [
        { title: '排名', width: '80' },
        { title: '姓名', val:'first', width: '100' }, 
        { title: '头像', val:'second', width: '80' },
        { title: '企业名称', val:'three', width: '120' },
        { title: '浏览人次', val:'four', width: '100' },
        { title: '关注者数', val:'five', width: '100' },
      ],
      cardList: [
      {
          first: '张三',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '李四',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '王五',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '张三',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '李四',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '王五',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '张三',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '李四',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '王五',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '张三',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '李四',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '张三',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '王五',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '王五',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '张三',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '李四',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '王五',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
        {
          first: '张三',
          second: '95',
          three: '裕正建设发展有限公司',
          four: '2000',
          five: '2000',
        },
      ]
    };
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#rightGuide {
  // margin-top: 50px;
  .rightGuideContainer {
    // width: 444px;
  }
}
</style>
    