<template>
  <div id="leftCode">
    <div class="leftCodeContainer">
      <ContentTop title="小程序使用人数情况" :type="1" />
      <div class="item-chart-top">
        <Echart
          :options="options"
          id="bottomLeftChart1"
          height="380px"
          width="100%"
        ></Echart>
      </div>
    </div>
  </div>
</template>
  
  <script>
import echarts from "echarts";
import Echart from "@/common/echart";
import ContentTop from "@/components/contentTop";
export default {
  components: {
    Echart,
    ContentTop,
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#303030",
          borderColor: "#2DCDFF", // 修改字体颜色
          borderWidth: 1,
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            console.log(params);
            let res = "";
            res = `
                        <div style="padding:8px 12px;">
                          <div>小程序使用情况：</div>
                          <div style="font-size: 12px;padding-top: 6px">总数：${params[0]?.data}人</div>
                          <div style="font-size: 12px;">较去年：12%  <span class="iconfont icon-shang" style="font-size: 16px; color: #e93329" /></div>
                        </div>`;
            return res;
          },
        },
        grid: {
          top: "10%",
          left: "8%",
          right: "0%",
          bottom: "12%",
        },
        xAxis: {
          data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
        },
        yAxis: [
          {
            name: "万人",
            // data: "value",
            type: "value",
            axisLabel: {
              formatter: function (v) {
                return v / 10000;
              },
            },
          },
        ],
        series: [
          {
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "rgba(8,253,254,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(8,253,254,1)",
                    },
                  ],
                  false
                ),
              },
            },
            data: [23390, 18990, 21180, 17900, 32650, 44650, 39960, 23390, 18990, 21180, 17900, 32650],
            type: "bar",
            barWidth: 10,
          },
          {
            data: [21000, 38990, 41000, 11900, 22650, 14650, 29960, 21000, 38990, 41000, 11900, 22650],
            type: "line",
            itemStyle: {
              color: "#056FEB",
            },
            lineStyle: {
              width: 2,
            },
          },
        ],
      },
    };
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
#leftCode {
  margin-top: 24px;
  .leftCodeContainer {
    height: 500px;
    width: 100%;
    // padding: 20px 30px;
  }
}
</style>
  