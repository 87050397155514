<template>
  <div class="box" :style="`background-color:${bgColor};`">
    <div class="inner" :style="`color:${color};`">{{ rank }}</div>
  </div>
</template>
    
    <script>
export default {
  props: {
    bgColor: { type: String, default: "#056FEB" },
    color: { type: String, default: "#88BFFF" },
    rank: { type: Number, default: 0 },
  },
};
</script>
    
    <style lang="scss" scoped>
.box {
    margin-left: 6px;
  height: 24px;
  width: 31px;
  /*居中*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transform: skew(-20deg);

  position: relative;
  .inner {
    transform: skew(20deg);
    font-size: 14px;
    font-weight: 700;
  }
}
</style>